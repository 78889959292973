import axios from "../instance";
const companyId = localStorage.getItem("companyId");
import addQuery from "../../utils/addQuery";

export default {
  // getAll: (sales_type, status, status1, status2) => {
  //   if (status && !status1 && !status2) {
  //     return axios.api
  //       .get(
  //         `/salesOrder?sales_type=${sales_type}&status=${status}&companyId=${companyId}`
  //       )
  //       .then((response) => response.data);
  //   }
  //   if (status && status1 && !status2) {
  //     return axios.api
  //       .get(
  //         `/salesOrder?sales_type=${sales_type}&status=${status}&&status=${status1}&companyId=${companyId}`
  //       )
  //       .then((response) => response.data);
  //   }
  //   if (status && status1 && status2) {
  //     return axios.api
  //       .get(
  //         `/salesOrder?sales_type=${sales_type}&status=${status}&status=${status1}&status=${status2}&companyId=${companyId}`
  //       )
  //       .then((response) => response.data);
  //   }
  // },
  getAll: async (payload) => {
    const result = await addQuery(payload);

    return axios.api
      .get(`/salesOrder?${result}`)
      .then((response) => response.data);
  },
  getOne: (id) => {
    return axios.api.get(`/salesOrder/${id}`).then((response) => response.data);
  },
  search: async (body, payload) => {
    const result = await addQuery(payload);

    return axios.api
      .post(`/salesOrder/search?${result}`, body)
      .then((response) => response.data);
  },
  create: (body) => {
    return axios.api
      .post("/salesOrder", body)
      .then((response) => response.data);
  },
  update: (id, body) => {
    return axios.api
      .put(`/salesOrder/${id}`, body)
      .then((response) => response.data);
  },
  delete: (id) => {
    return axios.api
      .delete(`/salesOrder/${id}`)
      .then((response) => response.data);
  },
};
